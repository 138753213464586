//<Header title={title} date={date} cover={image} />
//make like standard corey page
//style and make a post title/date/author header component
//remember Col
import React from 'react';
import { graphql, Link, withPrefix } from 'gatsby';
import styled from 'styled-components'
import siteConfig from '../../data/siteConfig'
import PropTypes from 'prop-types';
import loadable from '@loadable/component'
import Hero from '../components/hero'
import SEO from '../components/SEO'
import Wrapper from '../components/wrapper'
import Content from '../components/content'
import '../components/styles/prism'
import Img from 'gatsby-image';

const SuggestionBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.wrapper};
  box-shadow: 3px 3px 15px 0px rgba(0,0,0,0.75);
	align-items: center;
	width: 100%;
`;
const PostSuggestion = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 3rem 0 3rem;
`;

const Date = styled.div`
	margin: 16px auto 2px 0;
	color: #9295a0;
`;

const Layout = loadable(() => import('../components/layout'))

const Post = ({ className, location, data, pageContext }) => {
  const { next, prev } = pageContext;
  const {html, frontmatter } = data.markdownRemark
  const { keywords } = siteConfig
  const {date, title } = frontmatter
  const image = frontmatter.cover.childImageSharp.fluid;

  return (
    <Layout location={location}>
      <SEO
        title={title}
        keywords={keywords}
      />

      <Hero
        heroImg={withPrefix('/images/cover.jpeg')}
        title={title}
      />

      <Wrapper className={className}>
				<blogContainer>
					<Img fluid={image} />
					<Date>{date}</Date>
					<Content input={html} />
				</blogContainer>
				<SuggestionBar>
					<PostSuggestion>
						{prev && (
							<Link to={prev.frontmatter.path}>
								Previous
								<h3>{prev.frontmatter.title}</h3>
							</Link>
						)}
					</PostSuggestion>
					<PostSuggestion>
						{next && (
							<Link to={next.frontmatter.path}>
 			   	        Next
 			   	        <h3>{next.frontmatter.title}</h3>
 		     		    </Link>
						)}
					</PostSuggestion>
				</SuggestionBar>
			</Wrapper>
		</Layout>
  );
};

export default styled(Post)`
	.page-content {
		max-width: 100%;
		margin-bottom: 40px;
	}
`;

Post.propTypes = {
  pageContext: PropTypes.shape({
    prev: PropTypes.object,
    next: PropTypes.object,
  }).isRequired,
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query($pathSlug: String!) {
    markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
      html
      frontmatter {
        date
        title
        cover {
          childImageSharp {
            fluid(
              maxWidth: 1920
              quality: 90
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
            resize(width: 900, quality: 90) {
              src
            }
          }
        }
      }
    }
  }
`;
